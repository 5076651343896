module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"YOUR_GOOGLE_ANALYTICS_TRACKING_ID","head":true,"anonymize":false,"respectDNT":false,"cookieDomain":"https://alexcomp.dp.ua"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":667},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"ru-UA","short_name":"АлексКомп","name":"АлексКомп — компьютерная помощь в Павлограде","description":"Чистим, настраиваем, ремонтируем и продаем компьютеры, ноутбуки, планшеты, мониторы, видеокарты и другие комплектующие в Павлограде.","start_url":"/","theme_color":"#fff176","display":"standalone","icon":"assets/images/favicon.png","cache_busting_mode":"name","crossOrigin":"use-credentials"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
